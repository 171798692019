import Typewriter from "typewriter-effect";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <code>
          <h1 className="App-logo">Nuvem</h1>
          <Typewriter
            options={{
              strings: [
                "<strong>Cloud operations</strong> made simple <strong>automation</strong>",
                "Coming <strong>Soon</strong> ...",
              ],
              autoStart: true,
              loop: true,
              deleteSpeed: 20,
            }}
          />
        </code>
      </header>
    </div>
  );
}

export default App;
